import React, { useEffect, useState } from 'react';
import loadable from '@loadable/component';

import { hasSubscription } from 'state/selectors/has-subscription';
import { isAuthenticated } from 'services/authentication-service';

import {
  LOGIN_ROUTE, LANDING_PAGE,
  REGISTRATION_START, SUBSCRIPTION_ROUTE,
  ACCOUNT_ROUTE, OUR_VALUES,
  ALL_CARDS_ROUTE,
} from 'constants/navigation';
import {
  LOGIN_ICON, HOME_NAV,
  REGISTER_ICON, SUBSCRIPTION_NAV,
  ACCOUNT_NAV, VALUES_NAV,
  LOYALTY_CARD_NAV,
} from 'constants/font-awesome';

const LazyValues = loadable(() => import('suspended-page/our-values'));

const loggedOutNav = [
  { route: LANDING_PAGE, icon: HOME_NAV, message: 'Home' },
  { route: OUR_VALUES, icon: VALUES_NAV, message: 'Values' },
  { route: LOGIN_ROUTE, icon: LOGIN_ICON, message: 'Login' },
  { route: REGISTRATION_START, icon: REGISTER_ICON, message: 'Register' },
];

const loggedInHasSubNav = [
  { route: LANDING_PAGE, icon: HOME_NAV, message: 'Home' },
  { route: OUR_VALUES, icon: VALUES_NAV, message: 'Values' },
  { route: ACCOUNT_ROUTE, icon: ACCOUNT_NAV, message: 'Account' },
  { route: ALL_CARDS_ROUTE, icon: LOYALTY_CARD_NAV, message: 'Cards' },
];

const loggedInNoSubNav = [
  { route: LANDING_PAGE, icon: HOME_NAV, message: 'Home' },
  { route: OUR_VALUES, icon: VALUES_NAV, message: 'Values' },
  { route: SUBSCRIPTION_ROUTE, icon: SUBSCRIPTION_NAV, message: 'Subscription' },
  { route: ALL_CARDS_ROUTE, icon: LOYALTY_CARD_NAV, message: 'Cards' },
];

const OurValuesPage = () => {
  const [navItems, setNavItems] = useState(loggedOutNav);

  const authenticated = isAuthenticated();

  useEffect(() => {
    const getSubNavigation = async () => {
      const sub = await hasSubscription();
      setNavItems(sub ? loggedInHasSubNav : loggedInNoSubNav);
    };

    if (authenticated) {
      getSubNavigation();
    } else {
      setNavItems(loggedOutNav);
    }

    // eslint-disable-next-line
  }, [authenticated]);

  return (
    <LazyValues navItems={navItems}/>
  );
};

export default OurValuesPage;
